<template>
  <div class="px-4 p-sm-5 container">
    <div class="heading d-flex justify-content-between mb-5">
      <h2>Connecter une boutique externe</h2>
    </div>

    <div class="description">Synchronisez automatiquement votre inventaire <strong>Le bon panier</strong> depuis votre site de vente en ligne existant.</div>

    <div class="card mt-4">
      <div class="card-header">Plateforme</div>
      <div class="card-body py-5">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="mb-4">
            <button class="btn btn-primary" @click="$router.push({ name: 'connectedStore.setup.shopify' })">Shopify</button>
          </div>

          <div class="mb-4">
            <button class="btn btn-primary" @click="$router.push({ name: 'connectedStore.setup.woocommerce' })">WooCommerce</button>
          </div>

          <div class="mb-4">
            <button class="btn btn-primary" disabled>Lightspeed eCom</button>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-4">
      <div class="card-header">Tutoriels vidéo</div>
      <div class="card-body py-5">
        <div class="d-flex flex-column flex-xl-row justify-content-center align-items-center">
          <div class="video-wrapper m-2">
            <h4 class="text-left blockquote p-2">Comment connecter et importer une boutique Shopify sur le bon panier.</h4>
            <youtube-video id="76nvClGMP-U" title="Comment connecter et importer une boutique Shopify sur le bon panier." />
          </div>

          <div class="video-wrapper m-2">
            <h4 class="text-left blockquote p-2">Comment connecter et importer une boutique WooCommerce sur le bon panier.</h4>
            <div class="video-container">
              <youtube-video id="m6oSZKkajrw" title="Comment connecter et importer une boutique WooCommerce sur le bon panier." />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.description {
  font-size: 1rem;
}
.video-wrapper {
  width: 50%;
}
@media (max-width: 1194px) {
  .video-wrapper {
    width: 100%;
  }
}
</style>
<script>
import YoutubeVideo from '../common/YoutubeVideo'
export default {
  components: { YoutubeVideo }
}
</script>
