<template>
  <app-layout>
    <div class="px-4 p-sm-5 container">
      <div class="mb-4">
        <router-link class="" to="/connected-store" active-class="active">
          <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
        </router-link>
      </div>

      <div class="heading d-flex justify-content-between mb-5">
        <h2>Connexion WooCommerce</h2>
      </div>

      <p>Veuillez entrer la clé API de votre boutique WooCommerce.</p>

      <p>Pour plus d'information sur comment créer une clé API, vous pouvez consulter le lien suivant (en anglais):
        <br><a href="https://docs.woocommerce.com/document/woocommerce-rest-api/" target="_blank">Documentation WooCommerce REST API</a></p>

      <p>Note: la clé API doit avoir des permissions en lecture et en écriture.</p>

      <div class="mb-4 card">
        <div class="card-body">
          <v-dynamic-form
            :fields="fields"
            :initial-values="initialValues"
            @change="handleChange"
            :errors="errors"
          ></v-dynamic-form>
        </div>
      </div>

      <button class="btn btn-primary" :disabled="isProcessing" @click="submit">Continuer</button>
    </div>
  </app-layout>
</template>

<script>
import userData from '@/mixins/user-data'
import handleForm from '@/mixins/handle-form'
import http from '@/utils/http'

export default {
  mixins: [userData, handleForm],
  data () {
    return {
      isProcessing: false,
      form: {
        siteUrl: '',
        consumerKey: '',
        consumerSecret: ''
      }
    }
  },
  computed: {
    fields () {
      return [
        {
          name: 'siteUrl',
          label: 'Adresse de votre boutique (incluant https://)',
          required: true
        },
        {
          name: 'consumerKey',
          label: 'Clé publique (Consumer Key)',
          required: true
        },
        {
          name: 'consumerSecret',
          label: 'Clé privée (Consumer Secret)',
          required: true
        }
      ]
    },
    validationRules () {
      return {
        siteUrl: 'required|url',
        consumerKey: 'required',
        consumerSecret: 'required'
      }
    }
  },
  methods: {
    handleChange (values) {
      this.form = {
        ...this.form,
        ...values
      }
    },
    async submit () {
      if (!this.validate(this.form, this.validationRules)) {
        return
      }

      this.isProcessing = true

      try {
        await http.post(`/v1/stores/${this.currentStoreId}/connected-stores/woocommerce`, {
          ...this.form
        })

        this.$router.push('/connected-store')
      } catch (e) {
        if (e?.response?.data?.error === 'authenticationError') {
          this.$toasted.error('Impossible de se connecter, veuillez vérifier les identifiants.')
        } else if (e?.response?.data?.error === 'permissionError') {
          this.$toasted.error("La clé API n'a pas les accès en écriture.")
        } else if (e?.response?.data?.error === 'invalidEndpoint') {
          this.$toasted.error("L'URL n'est pas un site Woocommerce.")
        } else {
          this.$toasted.error('Un erreur est survenue, veuillez réessayer dans quelques instants.')
        }

        this.isProcessing = false
      }
    }
  }
}
</script>
